#progressWheel{
    height: 120px;
    width: 120px;
    transform: rotate(-90deg);
}
#progress,
#track {
    fill: transparent;
}
#progress {
    stroke: rgb(247, 198, 255);
    stroke-width: 6px;
    stroke-linecap: round;
    /*stroke-dashoffset: 140; !* Change number value to shift progress bar *!*/
    stroke-dasharray: 416;
}
#track {
    stroke: rgb(247, 198, 255, 0.25);
    stroke-width: 6px;
}
