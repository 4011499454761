.colour-bg {
    /*background-color: #201D2C;*/
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.img-bg {
    background-image: url("/public/images/11cc3a0a69b7380f5784b055b4e14b7c.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    mix-blend-mode: normal;
    opacity: 0.16;
    filter: blur(130px);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0
}

.content{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    min-height: 100vh;
}
