.hero-image-background {
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    height: 50vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.hero {
    z-index: 2;
}
